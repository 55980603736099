import React from "react";
import {CountMeasurement, CountMeasurementItem, RemoteSymbol} from "../models/editor";
import {useDispatch} from "react-redux";
import "./groupItemPresenter.scss";
import {useHighlightItem, useHighlightSetter} from "../hooks/highlight";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import IconVisibleButton from "./IconVisibleButton";
import {
    DeleteChildItemActionPayload,
    PenLine,
    SetItemVisibilityActionPayload,
    UpdateAssetIdActionPayload,
} from "../../../../base-konva/types";
import IndividualAssetAssignment from "./IndividualAssetAssignment";
import {SymbolType} from "../icons/symbol_pack/default";
import { useAssetAssignment } from '../providers/AssetAssignmentProvider';

interface CountGroupItemProps {
    parentId: string,
    item: CountMeasurementItem | PenLine,
    onDelete: ActionCreatorWithPayload<DeleteChildItemActionPayload, string>,
    setItemVisibility: ActionCreatorWithPayload<SetItemVisibilityActionPayload, string>,
    updateAssetId?: ActionCreatorWithPayload<UpdateAssetIdActionPayload, string>,
    disabled?: boolean,
    assetTypeId?: number
    isAssetPlan: boolean,
    symbol?: SymbolType
    color?: string
    remoteSymbol?: RemoteSymbol,
    countGroup?: CountMeasurement
}

const GroupItemPresenter: React.FC<CountGroupItemProps> = (
    {
        parentId,
        item,
        onDelete,
        setItemVisibility,
        updateAssetId,
        disabled,
        assetTypeId,
        isAssetPlan,
        symbol,
        color,
        remoteSymbol,
        countGroup
    }) => {
    const dispatch = useDispatch();
    const highlightActions = useHighlightSetter(item.id);
    const {isItemHover} = useHighlightItem(item.id);
    const {getCurrentItem} = useAssetAssignment();

	const toggleVisibility = () => {
		dispatch(setItemVisibility({id: item.id, parentId, visible: !item.visible}))
	}

    const handleUpdateAssetId = (newAssetId: number) => {
        if (updateAssetId) {
            dispatch(updateAssetId({parentId: parentId, itemId: item.id, assetId: newAssetId}));
        }
    };

    function handleUnassignAsset() {
        if (updateAssetId) {
            dispatch(updateAssetId({parentId: parentId, itemId: item.id, assetId: 0}));
        }
	}

    const isCountMeasurementItem = (item: CountMeasurementItem | PenLine): item is CountMeasurementItem => {
        return (item as CountMeasurementItem).assetId !== undefined;
    };

	const handleDelete = () => {
		if (!isAssetPlan) {
			!disabled && dispatch(onDelete({parentId, id: item.id}));
		} else {
			handleUnassignAsset()
		}
	}

	const cls = [
		"group-item-presenter",
		isItemHover && "is-hover",
	].filter(Boolean).join(" ");

	const actionsButtonCls = [
		"group-item-presenter_actions_button",
		disabled && "disabled"
	].filter(Boolean).join(" ");

    return (
        <div className={cls}
             onMouseEnter={highlightActions.setHighlight}
             onMouseLeave={highlightActions.clearHighlight}>
            <div className={"group-item-presenter_label"}>
                {!!isCountMeasurementItem(item) && Boolean(item.assetId) ? `${item.assetId} - ` : null}{item.name}
            </div>

            <div className={"group-item-presenter_actions"}>
                {!isAssetPlan ? <IconVisibleButton onClick={toggleVisibility} visible={item.visible}/> : countGroup ?
                    <IndividualAssetAssignment
                        countGroup={countGroup}
                        id={item.id}
                        materialName={item.name}
                        assetTypeId={assetTypeId}
                        color={color}
                        symbol={symbol}
                        remoteSymbol={remoteSymbol}
                        toggleOpen={getCurrentItem(countGroup)?.id === item.id}
                        onAssetIdChange={handleUpdateAssetId}
                        hasAssetId={!!isCountMeasurementItem(item) && Boolean(item.assetId)}
                        assetId={isCountMeasurementItem(item) && Boolean(item.assetId) ? item.assetId : undefined}
                    /> : null
                }
                <div className={actionsButtonCls} onClick={handleDelete}>
                    &times;
                </div>
            </div>
        </div>
    );
};

export default GroupItemPresenter
