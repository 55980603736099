import { AxiosInstance } from "axios";
import Asset from "../models/Asset";
import AssetCreationResult from "../models/AssetCreationResult";

export default class AssetsApi {
    constructor(public readonly axiosInstance: AxiosInstance) {}

    fetchAssets(siteId: number, assetTypeId: number): Promise<Asset[]> {
        const url = `/api/assets?siteId=${siteId}&assetTypeId=${assetTypeId}`;
        return this.axiosInstance.get(url).then(resp => resp.data.map(Asset.fromJson));
    }

    postNewAsset(siteId: number, assetTypeId: number): Promise<AssetCreationResult> {
        const url = `/api/${siteId}/assets`;
        const body = { assetTypeId };

        return this.axiosInstance.post(url, body)
            .then(resp => {
                const creationResult = AssetCreationResult.fromJson(resp.data);
                return creationResult;
            })
            .catch(err => {
                throw err;
            });
    }
}