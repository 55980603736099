import React from "react";
import {useTranslation} from "react-i18next";
import {get} from "../../../../../../../utils/ClassNameUtils";
import IconCut from "../../../icons/IconCut";

type DeductionItemProps = {
	onDeductionClick: () => void
	active: boolean
	value: string
	onDelete: () => void
}

export function DeductionItem({onDeductionClick, active, value, onDelete}: DeductionItemProps) {
	const {t} = useTranslation();
	const handleDelete = () => {onDelete()}

	return (
		<div className={get("group-item-presenter", {"is-hover": active})}>
			<div className="group-item-presenter_label is-clickable" onClick={onDeductionClick}>
				<div className="group-item-presenter_label_icon">
					<IconCut/>
				</div>
				{`${t("editor.area.deductionItemName")} (${value})`}
			</div>
			<div className="group-item-presenter_actions">
				<div className="group-item-presenter_actions_button" onClick={handleDelete}>
					&times;
				</div>
			</div>
		</div>
	)
}