import React from 'react';
import { useTranslation } from "react-i18next";
import { useAssetAssignment } from '../providers/AssetAssignmentProvider';
import { Status } from "../../../../../models/enums";
import "./BulkAssetAssignment.scss"

interface AssetCustomFieldsComponentProps {
    parentId: string;
    assetTypeId: number | undefined;
    siteId?: number | undefined;
    itemId?: string | undefined;
    setAssetCustomFields?: (customFields: any[]) => void;
    assetCustomFields: any[];
    loadStatus?: Status;
}

const AssetCustomFieldsComponent: React.FC<AssetCustomFieldsComponentProps> = ({ parentId, assetTypeId, itemId, assetCustomFields, loadStatus }) => {
    const {t} = useTranslation();
    const {customFieldValues, setCustomFieldValues} = useAssetAssignment();
    const customFieldsLimit = 4;

    const handleInputChange = (index: number, value: any) => {
        const updatedValue = assetCustomFields[index].type === "Numeric" ? parseFloat(value) : value;
        setCustomFieldValues({
            ...customFieldValues,
            ...(itemId === undefined
                ? {
                    [parentId]: {
                        ...customFieldValues[parentId],
                        [index]: updatedValue,
                    },
                }
                : {
                    [itemId]: {
                        ...(customFieldValues[parentId] || {}),
                        ...customFieldValues[itemId],
                        [index]: updatedValue,
                    },
                }),
        });
    };

    const fieldValues = itemId && customFieldValues?.[itemId] !== undefined
        ? customFieldValues?.[itemId]
        : customFieldValues?.[parentId] ?? undefined;

    if (loadStatus === Status.LOADING) return <div>{t("projects.summary.itemsTable.loadAssetCustomFields.loading")}</div>;
    if (loadStatus === Status.ERROR) return <div>{t("projects.summary.itemsTable.loadAssetCustomFields.loadErrorDesc")}</div>;
    if (loadStatus === Status.NO_DATA) return <div>{t("projects.summary.itemsTable.loadAssetCustomFields.noDataDesc")}</div>;

    return (
        <div className='asset-custom-fields_box'>
            <ul className='asset-custom-fields_list'
                style={{ height: assetCustomFields.length >= customFieldsLimit ? 300 : 200 }}>
                {assetCustomFields.map((assetCustomField, index) => (
                    <li key={index} className='asset-custom-fields_text'>
                        <div className='asset-custom-fields_text'>
                            <p className='asset-custom-fields_label'>{assetCustomField.name}</p>
                            {assetCustomField.type === "Date" ? (
                                <input
                                    type="date"
                                    className='asset-custom-fields_input'
                                    value={fieldValues?.[index] || ''}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            ) : assetCustomField.type === "Numeric" ? (
                                <input
                                    type="number"
                                    className='asset-custom-fields_input'
                                    value={fieldValues?.[index] || ''}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className='asset-custom-fields_input'
                                    value={fieldValues?.[index] || ''}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AssetCustomFieldsComponent;
