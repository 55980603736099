import {useState} from "react";
import {Status} from "../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../api/api";
import LoggerService from "../services/LoggerService";
import NotificationService from "../services/NotificationService";
import AssetCreationResult from "../models/AssetCreationResult";

const useAssets = (siteId: number | undefined) => {
    const {t} = useTranslation();
    const [loadStatus, setLoadStatus] = useState(Status.IDLE);
    const [assets, setAssets] = useState<any[]>([]);

    const loadAssets = async (assetTypeId: number) => {
        setLoadStatus(Status.LOADING);

        try {
            if (siteId) {
                const fields = await apiInstance.assetsApi.fetchAssets(siteId, assetTypeId);
                if (fields.length === 0) {
                    setLoadStatus(Status.NO_DATA)
                    return;
                }
                setAssets(fields);
                setLoadStatus(Status.SUCCESS);
            }
        } catch (err) {
            setLoadStatus(Status.ERROR);
            LoggerService.logError(err);
            NotificationService.errorNotification(
                t("common.error"),
                t("projects.summary.itemsTable.loadAssets.loadErrorDesc")
            );
        }
    };

    const createAsset = async (siteId: number, assetTypeId: number) => {
        try {
            setLoadStatus(Status.LOADING);
            const creationResult: AssetCreationResult = await apiInstance.assetsApi.postNewAsset(siteId, assetTypeId);
            setLoadStatus(Status.SUCCESS);
            return Number(creationResult.id);
        } catch (err) {
            setLoadStatus(Status.ERROR);
            LoggerService.logError(err);
            NotificationService.errorNotification(
                t("common.error"),
                t("projects.summary.itemsTable.createAssets.loadErrorDesc")
            );
        }
    };

    return { assets, loadStatus, loadAssets, createAsset };
};

export default useAssets;
