import { AxiosInstance } from "axios";
import AssetCustomField from "../models/AssetCustomField";
import {CustomFieldUpdateResult} from "../models/AssetCustomFieldUpdate";

export default class AssetCustomFieldsApi {
    constructor(public readonly axiosInstance: AxiosInstance) {}

    fetchAssetCustomFields(assetTypeId: number): Promise<AssetCustomField[]> {
        const url = `/api/asset_custom_fields?assetTypeId=${assetTypeId}`;
        return this.axiosInstance.get(url).then(resp => resp.data.map(AssetCustomField.fromJson));
    }

    updateAssetCustomFields(siteId: number, assetId: number, customFieldId: number, value: any): Promise<CustomFieldUpdateResult> {
        const url = `/api/${siteId}/assets/${assetId}/customFields/${customFieldId}`;
        const body = { value };

        return this.axiosInstance.patch(url, body)
            .then(resp => {
                return CustomFieldUpdateResult.fromJson(resp.data);
            })
            .catch(err => {
                return new CustomFieldUpdateResult(false, [err.message || 'Unknown error']);
            });
    }
}
