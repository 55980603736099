import React, {useEffect, useState} from "react";
import {countActions, selectActiveCountGroupId} from "../../count/countSlice";
import {useDispatch, useSelector} from "react-redux";
import IconArrowSmall from "../../../icons/IconArrowSmall";
import Dropdown from "../../../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../../../components/ui/DropdownItem";
import {useTranslation} from "react-i18next";
import {CountMeasurement, CountMeasurementStyle} from "../../../models/editor";
import GroupItemPresenter from "../../../components/GroupItemPresenter";
//Reused length panel item styles
import "../length/lengthPanelItem.scss"
import MeasurementStyleSelectorModal from "../../../components/MeasurementStyleSelectorModal";
import ConfirmationModal from "../../../../../../../components/ui/ConfirmationModal";
import {useHighlightSetter} from "../../../hooks/highlight";
import {CountPanelItemModal} from "../../../../../../../models/enums";
import {EditorTool, MaterialType, MeasurementType} from "../../../models/enums";
import {configActions, selectActiveTool, selectEditorLockState} from "../../config/configSlice";
import {useEditorPanelDataContext} from "../EditorPanelProvider";
import {IconPin} from "../../../icons/side_panel/IconPin";
import {useMaterialModalContext} from "../material/MaterialModalProvider";
import {get} from "../../../../../../../utils/ClassNameUtils";
import {selectHighlightState} from "../../view/viewSlice";
import SymbolPreviewElement from "../../../../../../symbols/components/SymbolPreviewElement";
import IconVisibleButton from "../../../components/IconVisibleButton";
import {getId} from "../../../../../../../utils";
import BulkAssetAssignment from "../../../components/BulkAssetAssignment";
import {useAssetAssignment} from "../../../providers/AssetAssignmentProvider";

interface CountPanelItemProps {
	countGroup: CountMeasurement;
	isAssetPlan: boolean
}

const CountPanelItem: React.FC<CountPanelItemProps> = ({countGroup, isAssetPlan}) => {
	const dispatch = useDispatch();
	const [isExpanded, setIsExpanded] = useState(false);
	const {isAssigning, getCurrentItem} = useAssetAssignment();
	const [modal, setModal] = useState<CountPanelItemModal>(CountPanelItemModal.NONE);
	const {pinnedItems, findPinnedMeasurement} = useEditorPanelDataContext()
	const {openMaterialChangeModal, openOneOffChangeModal} = useMaterialModalContext()
	const {t} = useTranslation()
	const highlightActions = useHighlightSetter(countGroup.id);
	const editorLocked = useSelector(selectEditorLockState);
	const activeTool = useSelector(selectActiveTool);
	const countGroupActive = useSelector(selectActiveCountGroupId) === countGroup.id && activeTool === EditorTool.COUNT
	const isOneOff = countGroup.material.type === MaterialType.ONE_OFF;

	const {itemId: highlightedItemId} = useSelector(selectHighlightState);
	const isItemBarHover =
		countGroup.id === highlightedItemId ||
		countGroup.countItems.some(item => item.id === highlightedItemId)

	const openDeleteModal = () => setModal(CountPanelItemModal.DELETE);
	const openStyleModal = () => setModal(CountPanelItemModal.STYLE);
	const openChangeMaterialModal = () => openMaterialChangeModal({
		measurementId: countGroup.id,
		measurementStyle: countGroup.style,
		measurementType: EditorTool.COUNT,
	});
	const openChangeOneOffModal = (modalTitle: string) => () => openOneOffChangeModal({
		measurementId: countGroup.id,
		measurementStyle: countGroup.style,
		measurementType: EditorTool.COUNT,
		materialName: countGroup.material.name,
		modalTitle: modalTitle
	})
	const closeModal = () => setModal(CountPanelItemModal.NONE);

	// Determine if we should force expansion
	const shouldForceExpand = getCurrentItem(countGroup) && isAssigning;

	useEffect(() => {
		if (shouldForceExpand) {
			setIsExpanded(true);
		} else if (!isExpanded) {
			setIsExpanded(false);
		}
	}, [shouldForceExpand, isExpanded]);

	function toggleExpand() {
		setIsExpanded(!isExpanded)
	}

	const cls = [
		"panel-item",
		countGroupActive && "is-active",
		isExpanded && "is-expanded",
	].filter(Boolean).join(" ");

	function handleActivate() {
		const activationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.COUNT, actionId: activationId}));
		dispatch(countActions.activateCountGroup({id: countGroup.id, actionId: activationId}))
		dispatch(countActions.setVisibility({id: countGroup.id, visible: true, actionId: activationId}))
	}

	function handleDeactivate() {
		dispatch(countActions.clearActiveCountGroup({}))
	}

	function toggleActivate() {
		countGroupActive ? handleDeactivate() : handleActivate()
	}

	function toggleVisibility() {
		dispatch(countActions.setVisibility({id: countGroup.id, visible: !countGroup.visible}))
	}

	function handleStyleSave(style: CountMeasurementStyle) {
		closeModal();
		dispatch(countActions.changeStyle({id: countGroup.id, style}));
	}

	function handleDelete() {
		dispatch(countActions.deleteCountGroup({id: countGroup.id}));
	}

	function handleUnassignAsset() {
		// TODO
	}

	const pinnedMeasurement = findPinnedMeasurement(countGroup);

	function addToPinnedList() {
		pinnedItems.createPinnedMeasurement(
			countGroup.material.id,
			countGroup.material.type,
			MeasurementType.COUNT,
			countGroup.material.name,
			undefined,
			undefined,
			countGroup.style,
			countGroup.material.isAsset,
			countGroup.material.assetTypeId
		);
	}

	function removeFromPinnedList() {
		pinnedItems.deletePinnedMeasurement(pinnedMeasurement!.id)
	}

	return (
		<>
			{modal === CountPanelItemModal.STYLE ? (
				<MeasurementStyleSelectorModal
					isOpen={true}
					initialStyle={countGroup.style}
					measurementType={EditorTool.COUNT}
					onSave={(style) => handleStyleSave(style as CountMeasurementStyle)}
					onClose={closeModal}
				/>
			) : null}
			{modal === CountPanelItemModal.DELETE ? (
				<ConfirmationModal
					isOpen={true}
					text={t("editor.deleteItemConfirmationModalQuestion")}
					onConfirm={handleDelete}
					onClose={closeModal}
				/>
			) : null}
			<div className={cls}>
				<div className={get("panel-item_bar", {"is-hover": isItemBarHover})}
					 onMouseEnter={highlightActions.setHighlight}
					 onMouseLeave={highlightActions.clearHighlight}>
					<div className="panel-item_bar_icon"
						 onClick={toggleExpand}>
						<IconArrowSmall isActive={countGroupActive}/>
					</div>
					<div className="panel-item_bar_content" onClick={!isAssetPlan ? toggleActivate : undefined}>
						<div className={"panel-item_bar_content_symbol-preview"}>
							<SymbolPreviewElement color={countGroup.style.color}
												  symbol={countGroup.style.symbol}
												  remoteSymbol={countGroup.style.remoteSymbol}/>
						</div>
						<div className="panel-item_bar_content_label">
							{countGroup.material.name}
						</div>
						{!!pinnedMeasurement && (
							<div className="panel-item_bar_content_pin-icon"><IconPin/></div>
						)}
						<div className="panel-item_bar_content_spacer"/>
					</div>
					<div className={"panel-item_bar_props"}>&times;{countGroup.countItems.length}</div>
					{!isAssetPlan &&
						<div className={"panel-item_bar_actions"}>
							<IconVisibleButton onClick={toggleVisibility} visible={countGroup.visible}
											   isActive={countGroupActive}/>
							<Dropdown isActive={countGroupActive} disabled={editorLocked}>
								{isOneOff &&
									<>
										<DropdownItem label={t("common.buttons.edit")}
													  onClick={openChangeOneOffModal(t("projects.oneOffModal.title.editItem"))}/>
										<DropdownItem label={t("editor.convertToCatalogue")}
													  onClick={openChangeMaterialModal}/>
									</>}
								{!isOneOff &&
									<>
										<DropdownItem label={t("common.buttons.edit")} onClick={openStyleModal}/>
										<DropdownItem label={t("editor.changeMaterial")}
													  onClick={openChangeMaterialModal}/>
										<DropdownItem label={t("editor.convertToOneOff")}
													  onClick={openChangeOneOffModal(t("editor.convertToOneOff"))}/>
									</>}
								<DropdownItem label={t("common.buttons.delete")} onClick={openDeleteModal}/>
								{!!pinnedMeasurement ? (
									<DropdownItem label={t("editor.panel.pinnedItems.removeFromPinnedItems")}
												  onClick={removeFromPinnedList}/>
								) : (
									<DropdownItem label={t("editor.panel.pinnedItems.addToPinnedItems")}
												  onClick={addToPinnedList}/>
								)}
							</Dropdown>
						</div>
					}
					{
						isAssetPlan &&
						<div className={"panel-item_bar_actions"}>
							<BulkAssetAssignment color={countGroup.style.color}
												 symbol={countGroup.style.symbol}
												 countGroup = {countGroup}
												 updateAssetId={countActions.updateAssetId}
												 remoteSymbol={countGroup.style.remoteSymbol}
												 id={countGroup.id}
												 materialName={countGroup.material.name}
												 assetTypeId={countGroup.material.assetTypeId}
												 appendix={countGroup.countItems.length.toString()}
							/>
							<Dropdown isActive={countGroupActive} disabled={editorLocked}>
								<DropdownItem label={t("common.buttons.delete")} onClick={handleUnassignAsset}/>
							</Dropdown>
						</div>
					}
				</div>
				<div className={"panel-item_content"}>
					{
						countGroup.countItems.length
							? countGroup.countItems.map(item => (
								<GroupItemPresenter
									key={item.id}
									parentId={countGroup.id}
									item={item}
									disabled={editorLocked}
									setItemVisibility={countActions.setItemVisibility}
									updateAssetId={countActions.updateAssetId}
									onDelete={countActions.deleteCountItem}
									assetTypeId={countGroup.material.assetTypeId}
									isAssetPlan={isAssetPlan}
									color={countGroup.style.color}
									symbol={countGroup.style.symbol}
									remoteSymbol={countGroup.style.remoteSymbol}
									countGroup={countGroup}
								/>
							)) : null
					}
				</div>
			</div>
		</>
	);
};

export default CountPanelItem