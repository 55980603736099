import { AssetCreationResultApiDto } from "./restModels";
import SimproApiError from "./SimproApiError";

export default class AssetCreationResult {
	constructor(
		public readonly errors: SimproApiError[],
		public readonly id: string
	) {}

	public static fromJson(json: AssetCreationResultApiDto): AssetCreationResult {
		const errors = Array.isArray(json.unhandleableErrors)
			? json.unhandleableErrors.map((error) => SimproApiError.fromJson(error))
			: [];

		return new AssetCreationResult(errors, json.id);
	}
}