import React, {useState} from 'react'
import "./BulkAssetAssignment.scss";
import {
    Button,
    Paper,
    Popper
} from "@mui/material";
import IconMaterialAdd from "../../../../../components/icons/IconMaterialAdd";
import {CountMeasurement, RemoteSymbol} from "../models/editor";
import SymbolPreviewElement from "../../../../symbols/components/SymbolPreviewElement";
import {useTranslation} from "react-i18next";
import {SymbolType} from "../icons/symbol_pack/default";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AssetCustomFieldsComponent from "./AssetCustomFields";
import useAssetCustomFields from "../../../../../hooks/useAssetCustomFields";
import {Status} from "../../../../../models/enums";
import { useAssetAssignment } from '../providers/AssetAssignmentProvider';
import IconMaterial from "../../../../../components/icons/IconMaterial";
import AssetList from "./AssetList";
import useAssets from "../../../../../hooks/useAssets";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {UpdateAssetIdActionPayload} from "../../../../base-konva/types";
import {useDispatch} from "react-redux";
import {useProjectDetailContext} from "../../../../project-details/providers/ProjectDetailProvider";
import NotificationService from "../../../../../services/NotificationService";

interface BulkAssetAssignmentProps {
    symbol?: SymbolType
    color?: string
    remoteSymbol?: RemoteSymbol
    id: string;
    materialName: String;
    assetTypeId?: number;
    appendix?: String;
    countGroup: CountMeasurement;
    updateAssetId: ActionCreatorWithPayload<UpdateAssetIdActionPayload, string>,
}

const BulkAssetAssignment: React.FC<BulkAssetAssignmentProps> = (
    {
        id,
        materialName,
        assetTypeId,
        symbol,
        color,
        remoteSymbol,
        appendix,
        countGroup,
        updateAssetId
    }) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [arrowRef] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const {projectDetails: {project}} = useProjectDetailContext()
    const siteId = project?.siteId ?? 0;
    const [selectedOption, setSelectedOption] = useState('');
    const {loadAssets, assets} = useAssets(siteId);
    const [selectedAsset, setSelectedAsset] = useState<any | null>(null);
    const { loadStatus, assetCustomFields } = useAssetCustomFields(assetTypeId);
    const {setGroupFromBulkContinue, checkAllAssetAssigned} = useAssetAssignment();
    const CREATE_NEW = 'createNew';
    const MAP_TO_EXISTING = 'mapToExisting';

    function toggle() {
        setOpen(!open)
    }

    const close = React.useCallback(() => {
        setOpen(false)
        setSelectedOption('')
        setSelectedAsset(null)
    }, [])

    const handleCreateNewClick = () => {
        setSelectedOption(CREATE_NEW);
    }

    const handleMapToExisting = () => {
        if (assetTypeId) {
            loadAssets(assetTypeId)
        }
        setSelectedOption(MAP_TO_EXISTING);
    }

    const handleContinue = () => {
        if (selectedOption === MAP_TO_EXISTING) {
            countGroup.countItems.forEach((item) => {
                if (selectedAsset) {
                    dispatch(updateAssetId({parentId: countGroup.id, itemId: item.id, assetId: selectedAsset.id}));

                }
            });
            NotificationService.successNotification(
                t("common.success"),
                `${t("common.assetCreation.successAssetBulkAssignment")} ${appendix} items`
            )
        } else {
            setGroupFromBulkContinue(countGroup);
        }
        close();
    }

    function renderAppendix() {
        if (color) {
            return <div style={{display: "inline"}}>(&times;{appendix} items)</div>
        } else {
            return <div style={{display: "inline"}}>({appendix})</div>}
    }

    return (
        <>
            {countGroup && checkAllAssetAssigned(countGroup) ?
                <IconMaterial/>
                : <div ref={setAnchorEl} onClick={toggle}>
                    <IconMaterialAdd/>
                </div>
            }
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={'right-start'}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={close}>
                    <Paper className='bulk-assignment'>
                        <div className='bulk-assignment_title'>
                            <div className='bulk-assignment_symbol'>
                                {color ? <SymbolPreviewElement color={color}
                                                                symbol={symbol}
                                                                remoteSymbol={remoteSymbol}/> : null}
                            </div>
                            <div className='bulk-assignment_name'>
                                {t("common.assetCreation.bulkAssetAssignment")} {renderAppendix()}
                            </div>
                        </div>
                        <div className="bulk-assignment_content_label">
                            {materialName}
                        </div>
                        <div className='bulk-assignment_arrow'></div>
                        <div className='bulk-assignment_content'>
                            {selectedOption === CREATE_NEW ? (
                                <AssetCustomFieldsComponent
                                    parentId={id}
                                    assetTypeId={assetTypeId}
                                    siteId={siteId}
                                    assetCustomFields={assetCustomFields}
                                    loadStatus={loadStatus}
                                />
                            ) : selectedOption === MAP_TO_EXISTING ? (
                                <AssetList assets={assets}
                                           setSelectedAsset={setSelectedAsset}
                                           fromBulk={true}
                                           appendix={appendix}
                                />
                            ) : (
                                <>
                                    <Button className='bulk-assignment_button' onClick={handleCreateNewClick}>
                                        <span style= {{fontWeight: "bold"}}>{t("common.assetCreation.create")}</span>
                                        <span style= {{fontWeight: "normal"}}>{t("common.assetCreation.new") }</span>
                                    </Button>
                                    <Button className='bulk-assignment_button' onClick={handleMapToExisting}>
                                        <span style= {{fontWeight: "normal"}}>{t("common.assetCreation.mapTo")}</span>
                                        <span style= {{fontWeight: "bold"}}>{t("common.assetCreation.existing")}</span>
                                    </Button>
                                </>
                            )}
                        </div>
                        <div className='bulk-assignment_close'>
                            {selectedOption ? (
                                <>
                                    {loadStatus !== Status.NO_DATA && selectedOption === CREATE_NEW ? (
                                        <div className='asset-custom-fields_button asset-custom-fields_continue'
                                             onClick={handleContinue}>
                                            {t("common.buttons.continue")}
                                        </div>
                                    ) : (
                                        <div className='asset-custom-fields_button asset-custom-fields_continue'
                                             onClick={handleContinue}
                                             style={{
                                                 opacity: !selectedAsset ? 0.5 : 1,
                                                 pointerEvents: !selectedAsset ? 'none' : 'auto'
                                             }}>
                                            {t("common.buttons.save")}
                                        </div>
                                    )}
                                    <div className='asset-custom-fields_button asset-custom-fields_cancel'
                                         onClick={close}>
                                        {t("common.buttons.cancel")}
                                    </div>
                                </>
                            ) : (
                                <div className='bulk-assignment_close_button' onClick={close}>
                                    <div style={{color: "#4B4747FF", textAlign: "center"}}>&#x2715;</div>
                                </div>
                            )}
                        </div>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
};

export default BulkAssetAssignment;
