import {useEffect, useState} from "react";
import {Status} from "../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../api/api";
import LoggerService from "../services/LoggerService";
import NotificationService from "../services/NotificationService"
import AssetCustomField from "../models/AssetCustomField";
import {CustomFieldUpdateResult} from "../models/AssetCustomFieldUpdate";

function useAssetCustomFields(assetTypeId: number | undefined) {
    const {t} = useTranslation();
    const [loadStatus, setLoadStatus] = useState(Status.IDLE);
    const [assetCustomFields, setAssetCustomFields] = useState<AssetCustomField[]>([]);
    const [updateStatus, setUpdateStatus] = useState<Status>(Status.IDLE);

    useEffect(() => {
        async function loadAssetCustomFields() {
            setLoadStatus(Status.LOADING);

            try {
                if (assetTypeId) {
                    const fields =
                        await apiInstance.assetCustomFieldsApi.fetchAssetCustomFields(assetTypeId);

                    if (fields.length === 0) {
                        setLoadStatus(Status.NO_DATA)
                        return;
                    }

                    setAssetCustomFields(fields);
                    setLoadStatus(Status.SUCCESS);
                }
            } catch (err) {
                setLoadStatus(Status.ERROR);
                LoggerService.logError(err);
                NotificationService.errorNotification(
                    t("common.error"),
                    t("projects.summary.itemsTable.loadAssetCustomFields.loadErrorDesc")
                );
            }
        }

        loadAssetCustomFields();
    }, [assetTypeId, t]);

    const updateCustomField = async (siteId: number, assetId: number, customFieldId: number, customFieldName:string, value: any) => {
        setUpdateStatus(Status.LOADING);

        try {
            const result: CustomFieldUpdateResult = await apiInstance.assetCustomFieldsApi.updateAssetCustomFields(
                siteId, assetId, customFieldId, value
            );

            if (result.success) {
                setUpdateStatus(Status.SUCCESS);
            } else {
                setUpdateStatus(Status.ERROR);
                NotificationService.errorNotification(
                    t("common.error"),
                    `${t("projects.summary.itemsTable.loadAssetCustomFields.saveError")} 
                    ${customFieldName}`

                );
            }
        } catch (err) {
            setUpdateStatus(Status.ERROR);
            LoggerService.logError(err);
            NotificationService.errorNotification(
                t("common.error"),
                t("projects.summary.itemsTable.loadAssetCustomFields.loadErrorDesc")
            );
        }
    };

    return { assetCustomFields, loadStatus, updateCustomField, updateStatus };
}

export default useAssetCustomFields;
