import React, {useEffect, useState} from 'react'
import "./BulkAssetAssignment.scss";
import {
    Paper,
    Popper,
    Tabs,
    Tab
} from "@mui/material";
import IconMaterialAdd from "../../../../../components/icons/IconMaterialAdd";
import IconMaterial from "../../../../../components/icons/IconMaterial";
import {CountMeasurement, RemoteSymbol} from "../models/editor";
import SymbolPreviewElement from "../../../../symbols/components/SymbolPreviewElement";
import {useTranslation} from "react-i18next";
import {SymbolType} from "../icons/symbol_pack/default";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AssetCustomFieldsComponent from "./AssetCustomFields";
import useAssetCustomFields from "../../../../../hooks/useAssetCustomFields";
import AssetList from "./AssetList";
import useAssets from "../../../../../hooks/useAssets";
import {Status} from "../../../../../models/enums";
import { useAssetAssignment } from '../providers/AssetAssignmentProvider';
import {useProjectDetailContext} from "../../../../project-details/providers/ProjectDetailProvider";
import NotificationService from "../../../../../services/NotificationService";

interface IndividualAssetAssignmentProps {
    symbol?: SymbolType
    color?: string
    remoteSymbol?: RemoteSymbol
    id: string;
    materialName: string;
    assetTypeId?: number;
    toggleOpen: boolean;
    onAssetIdChange: (assetId: number) => void;
    hasAssetId: boolean;
    countGroup: CountMeasurement
    assetId?: number;
}

const IndividualAssetAssignment: React.FC<IndividualAssetAssignmentProps> = (
    {
        id,
        materialName,
        assetTypeId,
        symbol,
        color,
        remoteSymbol,
        toggleOpen,
        onAssetIdChange,
        hasAssetId,
        countGroup,
        assetId
    }) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [arrowRef] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(toggleOpen);
    const {loadStatus, assetCustomFields, updateCustomField} = useAssetCustomFields(assetTypeId);
    const [activeTab, setActiveTab] = useState(0);
    const {projectDetails: {project}} = useProjectDetailContext()
    const siteId = project?.siteId ?? 0;
    const {createAsset, loadAssets, assets} = useAssets(siteId);
    const [selectedAsset, setSelectedAsset] = useState<any | null>(null);
    const {
        hasNext,
        isAssigning,
        setIsAssigning,
        fromBulk,
        currentItemIndex,
        isFromBulk,
        setNextUnassignedAsset,
        setCustomFieldValues,
        customFieldValues: assignedCustomFieldValues
    } = useAssetAssignment();
    function toggle() {
        setOpen(!open)
        isFromBulk(false)
        setIsAssigning('')
    }

    const unsetCustomFields = React.useCallback(() => {
        const {[id]: _, ...newCustomFieldValues} = assignedCustomFieldValues;
        setCustomFieldValues(newCustomFieldValues);
    },[setCustomFieldValues, assignedCustomFieldValues, id])

    const close = React.useCallback(() => {
        setOpen(false)
        setIsAssigning('')
        unsetCustomFields()
        setSelectedAsset(null)
    }, [setIsAssigning, unsetCustomFields, setSelectedAsset])

    const handleSkip = () => {
        if (countGroup && fromBulk && hasNext(countGroup)) {
            setNextUnassignedAsset(countGroup);
        } else {
            setIsAssigning(''); // End assignment process when all items are done
        }
        unsetCustomFields()
        setOpen(false)
    }

    const handleUnassignAsset = async () => {
        onAssetIdChange(0);
        close();
    }

    const handleSave = async () => {
        const customFieldValues = Object.keys(
            assignedCustomFieldValues?.[id] || assignedCustomFieldValues?.[countGroup.id] || {}
        ).map((key) => ({
            customFieldId: assetCustomFields[Number(key)]?.id,
            customFieldName: assetCustomFields[Number(key)]?.name,
            value: assignedCustomFieldValues?.[id]?.[key] ||
                assignedCustomFieldValues?.[countGroup.id]?.[key] ||
                '',
        }));
        if (selectedAsset) {
            onAssetIdChange(selectedAsset.id)
            setActiveTab(0)
            NotificationService.successNotification(
                t("common.success"),
                t("common.assetCreation.successAssetAssignment")
            );
        } else if (assetTypeId) {
            if (hasAssetId && assetId) {
                for (const {customFieldId, customFieldName, value} of customFieldValues) {
                    if (value !== null && value !== undefined && value !== '' && siteId) {
                        await updateCustomField(siteId, assetId, customFieldId, customFieldName, value);
                    }
                }
                NotificationService.successNotification(
                    t("common.success"),
                    t("common.assetCreation.successUpdateAssetCustomField")
                )
            } else {
                const newAssetId = await createAsset(siteId, assetTypeId);
                if (newAssetId) {
                    NotificationService.successNotification(
                        t("common.success"),
                        t("common.assetCreation.successAssetCreation")
                    )
                    if (onAssetIdChange) {
                        onAssetIdChange(newAssetId);
                    }
                    for (const {customFieldId, customFieldName, value} of customFieldValues) {
                        if (value !== null && value !== undefined && value !== '' && siteId) {
                            await updateCustomField(siteId, newAssetId, customFieldId, customFieldName, value);
                        }
                    }
                }
            }
        }

        // skip to the next available asset after saving the Asset
        handleSkip()
    };

   function renderAppendix() {
        if (fromBulk) {
            return <div className='bulk-assignment_name'>
                {t("common.assetCreation.bulkAssetAssignment")}
                ({currentItemIndex + 1} of {countGroup.countItems.length.toString()} items)
            </div>
        } else {
            return <div className='bulk-assignment_name'>
                {t("common.assetCreation.assetCreationText")}
            </div>
        }
   }

    useEffect(() => {
        setOpen(toggleOpen && isAssigning !== '');
    }, [toggleOpen, isAssigning]);

    function loadAllAssets() {
        if (assetTypeId) {
            loadAssets(assetTypeId)
        }
    }

    return (
        <>
            <div ref={setAnchorEl} onClick={toggle}>
                {!hasAssetId ?
                    <IconMaterialAdd/>
                    : <IconMaterial/>}
            </div>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={'right-start'}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={close}>
                    <Paper className='bulk-assignment individual'>
                        <div className='bulk-assignment_title'>
                            <div className='bulk-assignment_symbol'>
                                {color ? <SymbolPreviewElement color={color}
                                                               symbol={symbol}
                                                               remoteSymbol={remoteSymbol}/> : null}
                            </div>
                            {renderAppendix()}
                        </div>
                        <div className="bulk-assignment_content_label">
                            {materialName}
                        </div>
                        <div className='bulk-assignment_arrow individual'></div>
                        {fromBulk || hasAssetId ? null :
                            <Tabs
                                value={activeTab}
                                onChange={(_, newValue) => setActiveTab(newValue)}
                                indicatorColor="primary"
                                textColor="inherit"
                                className={`bulk-assignment_tabs`}
                                variant={"fullWidth"}
                                sx={{
                                    "& .MuiTabs-indicator": {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab
                                    label={t('common.assetCreation.createNew')}
                                    className={activeTab === 0 ? "tabs_active" : "tabs_inactive"}

                                />
                                <Tab
                                    label={t('common.assetCreation.mapToExisting')}
                                    className={activeTab === 1 ? "tabs_active" : "tabs_inactive"}
                                    onClick={() => { loadAllAssets() }}
                                />
                            </Tabs>
                        }
                        <div className="bulk-assignment_content">
                            {activeTab === 0 ? (
                                <AssetCustomFieldsComponent
                                    parentId={countGroup.id}
                                    itemId={id}
                                    assetTypeId={assetTypeId}
                                    siteId={siteId}
                                    assetCustomFields={assetCustomFields}
                                    loadStatus={loadStatus}
                                />
                            ) : (
                                <AssetList fromBulk={false} assets={assets} setSelectedAsset={setSelectedAsset}/>
                            )}
                        </div>
                        <div className='bulk-assignment_close'>
                            <>
                                {loadStatus !== Status.NO_DATA && (
                                    <div className='asset-custom-fields_button asset-custom-fields_save'
                                         onClick={handleSave}
                                         style={{
                                             opacity: activeTab === 1 && !selectedAsset ? 0.5 : 1,
                                             pointerEvents: activeTab === 1 && !selectedAsset ? 'none' : 'auto'
                                         }}>
                                        {t('common.buttons.save')}
                                    </div>
                                )}
                                {fromBulk ?
                                    <div className='asset-custom-fields_button asset-custom-fields_save'
                                         onClick={handleSkip}>
                                        {t('common.buttons.skip')}
                                    </div> : null
                                }
                                {hasAssetId ?
                                    <div className='asset-custom-fields_button asset-custom-fields_save'
                                         onClick={handleUnassignAsset}>
                                        {t('common.assetCreation.unassign')}
                                    </div> : null
                                }
                                <div className='asset-custom-fields_button asset-custom-fields_cancel'
                                     onClick={close}>
                                    {t('common.buttons.cancel')}
                                </div>
                            </>

                        </div>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
};

export default IndividualAssetAssignment;
