import React, {createContext, useContext, useState} from 'react';
import {CountMeasurement, CountMeasurementItem} from "../models/editor";

interface AssetAssignmentContextType {
    isAssigning: string;
    customFieldValues: Record<string, any>;
    currentItemIndex: number;
    setCurrentItemIndex: (index: number) => void;
    setIsAssigning: (value: string) => void;
    setCustomFieldValues: (values: Record<string, any>) => void;
    hasNext: (value: CountMeasurement) => boolean;
    hasPrevious: () => boolean;
    fromBulk: boolean;
    isFromBulk: (fromBulk: boolean) => void;
    setNextUnassignedAsset: (value: CountMeasurement) => void;
    getCurrentItem: (value: CountMeasurement | undefined) => CountMeasurementItem | null;
    setGroupFromBulkContinue: (value: CountMeasurement) => void;
    checkAllAssetAssigned: (value: CountMeasurement) => boolean;
}

const AssetAssignmentContext = createContext<AssetAssignmentContextType | null>(null);

export const AssetAssignmentProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [isAssigning, setIsAssigning] = useState('');
    const [customFieldValues, setCustomFieldValues] = useState<Record<string, any>>({});
    const [fromBulk, isFromBulk] = useState(false);

    const hasNext = (countGroup: CountMeasurement) => currentItemIndex < countGroup.countItems.length - 1;
    const hasPrevious = () => currentItemIndex > 1;

    const getCurrentItem = (countGroup: CountMeasurement | undefined) => {
        if (countGroup?.id === isAssigning) {
            return countGroup?.countItems[currentItemIndex] || null;
        }
        return null;
    }

    const setGroupFromBulkContinue = (countGroup: CountMeasurement) => {
        checkAssignedAssets(countGroup, 0);
        setIsAssigning(countGroup?.id || ''); // Start the assignment process
        isFromBulk(true);
    }

    const setNextUnassignedAsset = (countGroup: CountMeasurement) => {
        checkAssignedAssets(countGroup, currentItemIndex +1)
    };

    const checkAssignedAssets = (countGroup: CountMeasurement, nextIndex: number) => {
        // Loop until we find an item with hasAsset = false or reach the end of the array
        while (nextIndex < countGroup?.countItems.length && countGroup?.countItems[nextIndex].assetId) {
            nextIndex++
        }

        // If we found a valid index, update currentIndex
        if (nextIndex < countGroup?.countItems.length) {
            setCurrentItemIndex(nextIndex);
        } else {
            // No unassigned asset found
            setIsAssigning('')
        }
    }

    const checkAllAssetAssigned = (countGroup: CountMeasurement) => {
        return (countGroup?.countItems || []).every((item) => !!item.assetId);
    };

    return (
        <AssetAssignmentContext.Provider
            value={{
                isAssigning,
                customFieldValues,
                fromBulk,
                currentItemIndex,
                setCurrentItemIndex,
                setIsAssigning,
                setCustomFieldValues,
                hasNext,
                hasPrevious,
                isFromBulk,
                setNextUnassignedAsset,
                getCurrentItem,
                setGroupFromBulkContinue,
                checkAllAssetAssigned
            }}
        >
            {children}
        </AssetAssignmentContext.Provider>
    );
};

export const useAssetAssignment = () => {
    const context = useContext(AssetAssignmentContext);
    if (!context) {
        throw new Error('useAssetAssignment must be used within AssetAssignmentProvider');
    }
    return context;
};
