export class CustomFieldUpdateResult {
    success: boolean;
    errors: string[];

    constructor(success: boolean, errors: string[] = []) {
        this.success = success;
        this.errors = errors;
    }

    static fromJson(json: any): CustomFieldUpdateResult {
        return new CustomFieldUpdateResult(
            json.success,
            json.errors || []
        );
    }
}
