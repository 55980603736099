import Axios, {AxiosInstance} from "axios";
import ProjectsApi from "./ProjectsApi";
import UsersApi from "./UserApi";
import {authorizationResponseInterceptor} from "./interceptors/response";
import DrawingsApi from "./DrawingsApi";
import SettingsApi from "./SettingsApi";
import CompaniesApi from "./CompaniesApi";
import CatalogsApi from "./CatalogsApi";
import AttachmentApi from "./AttachmentApi";
import PreBuildsApi from "./PreBuildsApi";
import MeasurementsApi from "./MeasurementsApi";
import SymbolsApi from "./SymbolsApi";
import PinnedMeasurementsApi from "./PinnedMeasurementsApi";
import TakeoffTemplatesApi from "./TakeoffTemplatesApi";
import SymbolRecognizerApi from "./SymbolRecognizerApi";
import AssetCustomFieldsApi from "./AssetCustomFieldsApi";
import AssetsApi from "./AssetsApi";

export type WithUrl<T> = {
	url: string,
	promise: () => Promise<T>
}

/**
 * Api timeout value in milliseconds
 */
export const API_TIMEOUT = 60000;

class ApiInstance {
	api: Api;

	constructor() {
		this.api = new Api();
	}

	create() {
		this.api = new Api();
	}

	get projectsApi(): ProjectsApi {
		return this.api.projectsApi;
	}

	get usersApi(): UsersApi {
		return this.api.usersApi;
	}

	get drawingsApi(): DrawingsApi {
		return this.api.drawingsApi;
	}

	get settingsApi(): SettingsApi {
		return this.api.settingsApi;
	}

	get companiesApi(): CompaniesApi {
		return this.api.companiesApi;
	}

	get catalogApi(): CatalogsApi {
		return this.api.catalogsApi;
	}

	get attachmentApi(): AttachmentApi {
		return this.api.attachmentApi;
	}

	get preBuildsApi(): PreBuildsApi {
		return this.api.preBuildsApi;
	}

	get measurementsApi(): MeasurementsApi {
		return this.api.measurementsApi;
	}

	get assetCustomFieldsApi(): AssetCustomFieldsApi {
		return this.api.assetCustomFieldsApi;
	}

	get assetsApi(): AssetsApi {
		return this.api.assetsApi;
	}

	get symbolsApi(): SymbolsApi {
		return this.api.symbolsApi;
	}

	get pinnedMeasurementsApi(): PinnedMeasurementsApi {
		return this.api.pinnedMeasurementsApi;
	}

	get takeoffTemplatesApi(): TakeoffTemplatesApi {
		return this.api.takeoffTemplatesApi;
	}

	get symbolRecognizerApi(): SymbolRecognizerApi {
		return this.api.symbolRecognizerApi;
	}
}

class Api {
	private readonly axiosInstance: AxiosInstance;
	public readonly projectsApi: ProjectsApi;
	public readonly usersApi: UsersApi;
	public readonly drawingsApi: DrawingsApi;
	public readonly settingsApi: SettingsApi;
	public readonly companiesApi: CompaniesApi;
	public readonly catalogsApi: CatalogsApi;
	public readonly attachmentApi: AttachmentApi;
	public readonly preBuildsApi: PreBuildsApi;
	public readonly measurementsApi: MeasurementsApi;
	public readonly symbolsApi: SymbolsApi;
	public readonly pinnedMeasurementsApi: PinnedMeasurementsApi;
	public readonly takeoffTemplatesApi: TakeoffTemplatesApi;
	public readonly symbolRecognizerApi: SymbolRecognizerApi;
	public readonly assetCustomFieldsApi: AssetCustomFieldsApi;
	public readonly assetsApi: AssetsApi;

	constructor() {
		this.axiosInstance = Api.createInstance();
		this.projectsApi = new ProjectsApi(this.axiosInstance);
		this.usersApi = new UsersApi(this.axiosInstance);
		this.drawingsApi = new DrawingsApi(this.axiosInstance);
		this.settingsApi = new SettingsApi(this.axiosInstance);
		this.companiesApi = new CompaniesApi(this.axiosInstance);
		this.catalogsApi = new CatalogsApi(this.axiosInstance);
		this.attachmentApi = new AttachmentApi(this.axiosInstance);
		this.preBuildsApi = new PreBuildsApi(this.axiosInstance);
		this.measurementsApi = new MeasurementsApi(this.axiosInstance);
		this.symbolsApi = new SymbolsApi(this.axiosInstance);
		this.pinnedMeasurementsApi = new PinnedMeasurementsApi(this.axiosInstance);
		this.takeoffTemplatesApi = new TakeoffTemplatesApi(this.axiosInstance);
		this.symbolRecognizerApi = new SymbolRecognizerApi(this.axiosInstance);
		this.assetCustomFieldsApi = new AssetCustomFieldsApi(this.axiosInstance);
		this.assetsApi = new AssetsApi(this.axiosInstance);
	}

	private static createInstance(): AxiosInstance {
		const headers: { [key: string]: string } = {};
		const axios = Axios.create({
			timeout: API_TIMEOUT,
			headers: headers
		})
		axios.interceptors.response.use(data => data, authorizationResponseInterceptor)
		return axios;
	}
}

export const apiInstance: ApiInstance = new ApiInstance();