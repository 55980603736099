import {AssetCustomFieldApiDto} from "./restModels";

export default class AssetCustomField {
    public value?: any;

    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly type: string,
        value?: any
    ) {
        if (value) {
            this.value = value;
        }
    }

    public static fromJson(json: AssetCustomFieldApiDto): AssetCustomField {
        return new AssetCustomField(json.id, json.name, json.type);
    }
}
