import moment from "moment";
import {JobSummaryApiDto} from "./restModels";
import {JobStage} from "./enums";
import LoggerService from "../services/LoggerService";
import ProjectStatus from "./ProjectStatus";

export default class JobSummary {
	constructor(public readonly id: number,
		public readonly name: string,
		public readonly stage: JobStage,
		public readonly customer: string,
		public readonly site: string,
		public readonly siteId: number,
		public readonly status: ProjectStatus | undefined,
		public readonly salesperson: string | undefined,
		public readonly dueDate: moment.Moment | undefined,
		public readonly hasDrawings: boolean
	) {}

	public static fromJson(json: JobSummaryApiDto): JobSummary {
		const stage = JobStage[json.stage];
		if (stage === undefined) {
			const error = new Error(`Unknown Job stage: ${json.stage}`);
			LoggerService.logError(error);
			throw error;
		}
		const status = json.status ? ProjectStatus.fromJson(json.status) : undefined;
		const dueDate = json.dueDate ? moment(json.dueDate) : undefined;
		return new JobSummary(json.id, json.name, stage, json.customer, json.site, json.siteId, status, json.salesperson,
			dueDate, json.hasDrawings);
	}
}
