import React, { useState } from "react";
import { TextField, List, ListItemText, ListItemButton, ListSubheader, InputAdornment } from "@mui/material";
import IconSearch from "../../../../../components/icons/IconSearch";
import {useTranslation} from "react-i18next";

interface AssetListProps {
    assets: any[];
    setSelectedAsset: (asset: any) => void;
    fromBulk: boolean;
    appendix?: String | undefined;
}

const AssetList: React.FC<AssetListProps> = ({ assets, setSelectedAsset, fromBulk, appendix}) => {
    const {t} = useTranslation();
    const [searchQuery, setSearchQuery] = useState("");
    const [showAll, setShowAll] = useState(false);
    const [localSelectedAsset, setLocalSelectedAsset] = useState<any | null>();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredAssets = assets.filter(asset =>
        asset.name.toLowerCase().includes(searchQuery) || asset.id.toString().toLowerCase().includes(searchQuery)
    );

    // Limit the displayed assets unless showAll is true
    const displayedAssets = showAll
        ? filteredAssets
        : filteredAssets.slice(0, 6);

    const handleAssetSelect = (asset: any) => {
        setLocalSelectedAsset(asset)
        setSelectedAsset(asset)
    };
    return (
        <div>
            {fromBulk && (
                <div style={{ marginBottom: "20px", fontWeight: "bold", fontSize: "12px", textAlign: "center" }}>
                    <p>Note!</p>
                    <p>You are assigning an asset to {appendix} items.</p>
                </div>
            )}
            <TextField
                label={t("common.assetCreation.searchAsset")}
                variant="outlined"
                fullWidth
                size={"small"}
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                    marginBottom: "10px"
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconSearch />
                        </InputAdornment>
                    ),
                }}
            />
            <List
                style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    fontSize: "10px",
                    borderRadius: "8px",
                    backgroundColor: "#f0f0f0"
                }}
                subheader={
                    <ListSubheader
                        style={{
                            backgroundColor: "#f0f0f0",
                            fontWeight: "bold",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            lineHeight: "30px",
                        }}
                    >
                        {t("common.assetCreation.assetMappingHeader")}
                        <div style={{borderBottom: "1px solid"}}></div>
                    </ListSubheader>
                }
            >
                {displayedAssets.map((asset, index) => (
                    <ListItemButton
                        key={index}
                        onClick={() => handleAssetSelect(asset)}
                        style={{
                            maxHeight: "100",
                            overflowY: "auto",
                            borderRadius: "8px",
                            backgroundColor: localSelectedAsset?.id === asset.id ? '#e0e0e0' : '#f0f0f0',
                            padding: "5px 16px"
                        }}
                    >
                        <ListItemText
                            style={{ fontSize: "10px", padding: "2px"}}
                            primary={asset.id + " - " + asset.name}
                        />
                    </ListItemButton>
                ))}
                {!showAll && filteredAssets.length > 6 && (
                    <ListItemButton
                        onClick={() => setShowAll(true)}
                        style={{
                            backgroundColor: '#f0f0f0',
                            padding: "5px 16px",
                            color: "#1976d2",
                            fontWeight: "bold"
                        }}
                    >
                        <ListItemText primary={t("common.assetCreation.showMore")} />
                    </ListItemButton>
                )}
            </List>
        </div>
    );
};

export default AssetList;
